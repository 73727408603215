<template>
    <div class="vstack gap-4">
        <div class="d-flex flex-column align-items-center bg-green rounded p-5">
            <b-iconstack class="text-white" font-scale="6">
                <b-icon stacked icon="megaphone" scale="0.5" rotate="-45" shift-h="-0.5"></b-icon>
                <b-icon stacked icon="circle"></b-icon>
            </b-iconstack>
            <div class="text-white fs-4 mb-2">Nova Campanha</div>
            <b-button variant="light" class="text-green px-4" @click="$router.replace('/manageCampaign')">Criar</b-button>
        </div>
        <div class="bg-white text-purple rounded">
            <div class="d-flex justify-content-between align-items-center p-3 border-bottom">
                <div class="fs-5">Campanhas</div>
                <div class="d-flex align-items-center">
                    <div class="text-nowrap">Total de Campanhas</div>
                    <div class="fs-5 bg-purple text-white rounded-pill mx-3 px-2">{{ campaigns.length || 0 }}</div>
                    <b-dropdown ref="dropdownFilters" right menu-class="p-0 mt-2 shadow-sm" toggle-class="border border-dark-subtle rounded-pill px-3 py-2 text-secondary small-text hstack gap-2" variant="white" text="Filtrar">
                        <b-form class="vstack gap-3 p-3" @submit.prevent="getCampaignsFilter" style="width: 20em">
                            <b-form-group class="w-100" label="Nome:" label-class="text-purple">
                                <b-form-input type="text" class="border-grey" placeholder="Nome" v-model="filters.name"/>
                            </b-form-group>
                            <b-form-group class="w-100" label="Período:" label-class="text-purple">
                                <b-form-select class="w-100 border-grey rounded py-1-5 px-2" v-model="filters.date">
                                    <b-form-select-option value="1">
                                        Hoje
                                    </b-form-select-option>
                                    <b-form-select-option value="2">
                                        Esta Semana
                                    </b-form-select-option>
                                    <b-form-select-option value="3">
                                        Este Mês
                                    </b-form-select-option>
                                    <b-form-select-option value="4">
                                        Definir
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <div class="w-100" v-if="filters.date == 4">
                                <div class="w-50 pe-1 d-inline-block">
                                    <b-form-group label="De:">
                                        <b-form-input type="date" v-model="filters.startDate"></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="w-50 ps-1 d-inline-block">
                                    <b-form-group label="Até:">
                                        <b-form-input type="date" v-model="filters.endDate"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                            <b-form-group class="w-100" label="Status:" label-class="text-purple">
                                <b-form-select class="w-100 border-grey rounded py-1-5 px-2" v-model="filters.status">
                                    <b-form-select-option value="1">
                                        Falha no Envio
                                    </b-form-select-option>
                                    <b-form-select-option value="2">
                                        Aguardando Envio
                                    </b-form-select-option>
                                    <b-form-select-option value="3">
                                        Em Andamento
                                    </b-form-select-option>
                                    <b-form-select-option value="4">
                                        Finalizada
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-button type="submit" class="text-white" variant="green">Filtrar</b-button>
                        </b-form>
                    </b-dropdown>
                </div>
            </div>
            <div>
                <div class="d-flex align-items-center justify-content-center rounded p-3" v-if="loading">
                    <b-spinner />
                </div>
                <div v-else-if="campaigns.length">
                    <b-row class="m-0 gap-2 px-3 py-2">
                        <b-col class="p-0" cols="3">
                            <div class="fw-bold">Nome</div>
                        </b-col>
                        <b-col class="p-0" cols="2">
                            <div class="fw-bold">Data/Hora de Envio</div>
                        </b-col>
                        <b-col class="p-0">
                            <div class="fw-bold">Chatbot Ativo</div>
                        </b-col>
                        <b-col class="p-0">
                            <div class="fw-bold">Formato da Campanha</div>
                        </b-col>
                        <b-col class="p-0">
                            <div class="fw-bold">Status da Campanha</div>
                        </b-col>
                        <b-col class="p-0" cols="2"></b-col>
                    </b-row>
                    <b-row class="m-0 gap-2 px-3 py-3 border-top-grey d-flex align-items-center" v-for="campaign in campaigns" :key="campaign._id">
                        <b-col class="p-0 text-truncate" cols="3">
                            <div class="text-truncate">{{ campaign.name }}</div>
                        </b-col>
                        <b-col class="p-0 text-truncate" cols="2">
                            <div class="text-truncate">{{ campaign.sendingMessages.immediate ? campaign.createdAt : campaign.sendingMessages.schedulingDate | date('DD/MM/YYYY HH:mm:ss') }}</div>
                        </b-col>
                        <b-col class="p-0 text-truncate">
                            <div class="">
                                <b-icon icon="x" font-scale="2" v-if="campaign.automation.enable" />
                                <b-icon icon="check" font-scale="2" v-else />
                            </div>
                        </b-col>
                        <b-col class="p-0 text-truncate">
                            <div class="text-truncate">{{ campaign.sendingMessages.immediate ? "Envio Imediato" : "Agendamento" }}</div>
                        </b-col>
                        <b-col class="p-0 text-truncate">
                            <div class="hstack gap-2">
                                {{ campaign.status }}
                                <div v-b-tooltip.hover.bottom="{ variant: 'purple', customClass: 'top-0', boundary: 'document' }" title="Mantenha contato com nossa equipe de suporte!" v-if="campaign.status === 'Falha no Envio'" role="button">
                                    <b-iconstack class="darkenTextOnHover" role="button" font-scale="1.3"> 
                                        <b-icon class="text-purple" stacked icon="circle-fill"></b-icon>
                                        <b-icon stacked class="text-white" icon="info"></b-icon>
                                    </b-iconstack>
                                </div>
                            </div>
                        </b-col>
                        <b-col class="p-0 hstack justify-content-end gap-1" cols="2">
                            <b-button variant="green" class="text-white manage-btn mx-0" v-b-tooltip.hover.bottom="{ variant: 'green', customClass: 'top-0', boundary: 'document' }" title="Relatório" @click.prevent="$router.replace(`/reportCampaign?id=${campaign._id}`)">
                                <b-icon icon="clipboard-data"></b-icon>
                            </b-button>
                            <b-button variant="purple" class="text-white manage-btn mx-0" v-b-tooltip.hover.bottom="{ variant: 'purple', customClass: 'top-0', boundary: 'document' }" title="Preview" @click.prevent="setPreview(campaign.templateId)" v-b-modal.modalPreview>
                                <b-icon icon="eye"></b-icon>
                            </b-button>
                            <!-- <b-button variant="green" class="text-white manage-btn mx-0" v-b-tooltip.hover.bottom="{ variant: 'green', customClass: 'top-0', boundary: 'document' }" title="Editar" @click.prevent="$router.replace(`/manageCampaign?id=${campaign.id}`)">
                                <b-icon icon="pencil-square"></b-icon>
                            </b-button> -->
                            <b-button variant="warning" class="text-white manage-btn mx-0" v-b-tooltip.hover.bottom="{ variant: 'warning', customClass: 'top-0', boundary: 'document' }" title="Cancelar Envio" :disabled="campaign.status != 'Em Andamento' && campaign.status != 'Aguardando Envio'" @click.prevent="selectCampaign(campaign)" v-b-modal.cancelCampaign>
                                <b-icon icon="x"></b-icon>
                            </b-button>
                            <b-button variant="red" class="text-white manage-btn mx-0" v-b-tooltip.hover.bottom="{ variant: 'red', customClass: 'top-0', boundary: 'document' }" title="Remover" @click.prevent="selectCampaign(campaign)" v-b-modal.deleteCampaign>
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
                <div class="p-3 text-purple text-center" v-else>
                    Nenhuma campanha encontrada!
                </div>
            </div>
        </div>
        <b-modal id="modalPreview" ref="modalPreview" :title="template ? `Preview: ${template.elementName}` : 'Preview'" hide-footer body-class="p-0" header-class="py-0">
            <div class="p-3">
                <Whatsapp :loading="loadingMessages" :messages="messages" :height="'40vh'" :mediaURL="mediaURL" />
                <!-- <div class="mt-3">
                    <b-button class="btn-success" @click="$bvModal.hide('modalPreview')">Fechar</b-button>
                </div> -->
            </div>
        </b-modal>
        <b-modal id="send" ref="send" :title="template ? `Enviar Campanha: ${template.elementName}` : 'Enviar Campanha'" hide-footer body-class="p-0" header-class="py-0">
            <div class="vstack gap-3 p-3">
                <Whatsapp :loading="loadingMessages" :messages="messages" :height="'40vh'" :mediaURL="mediaURL" />
                <div class="hstack gap-2">
                    <b-button class="text-white" variant="green" @click="sendCampaign">Enviar</b-button>
                    <b-button class="border-grey" variant="outline-secondary" @click="$bvModal.hide('send')">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="deleteCampaign" ref="deleteCampaign" title="Remover Campanha" hide-footer body-class="p-0" header-class="py-0" size="sm" v-if="this.selectedCampaign">
            <form class="vstack" @submit.prevent="deleteCampaign()">
                <div class="text-secondary smaller-text p-3">Tem certeza de que deseja remover esta Campanha?</div>
                <div class="hstack gap-2 border-top p-3">
                    <b-button type="submit" class="text-white px-3 hstack gap-2" :disabled="deleting" variant="green">
                        <b-spinner small v-if="deleting"/>
                        Remover
                    </b-button>
                    <b-button class="border-grey" variant="outline-secondary" @click="$bvModal.hide('deleteCampaign')">Cancelar</b-button>
                </div>
            </form>
        </b-modal>
        <b-modal id="cancelCampaign" ref="cancelCampaign" title="Cancelar Campanha" hide-footer body-class="p-0" header-class="py-0" size="sm" v-if="this.selectedCampaign">
            <form class="vstack" @submit.prevent="cancelCampaign()">
                <div class="text-secondary smaller-text p-3">Tem certeza de que deseja cancelar esta Campanha?</div>
                <div class="hstack gap-2 border-top p-3">
                    <b-button type="submit" class="text-white px-3 hstack gap-2" :disabled="canceling" variant="green">
                        <b-spinner small v-if="canceling"/>
                        Cancelar
                    </b-button>
                    <b-button class="border-grey" variant="outline-secondary" @click="$bvModal.hide('cancelCampaign')">Cancelar</b-button>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import api from '../services/apiService.js'
import Whatsapp from './whatsapp.vue'

export default {
    components: {
        Whatsapp
    },
    props: [
        'user', 'mediaURL'
    ],
    mounted: function() {
        this.getCampaigns()
    },
    data() {
        return {
            campaigns: [],
            loading: false,
            loadingMessages: false,
            deleting: false,
            canceling: false,
            selectedCampaign: null,
            template: null,
            filters: {}
        }
    },
    computed: {
        messages() {
            let template = this.template

            if (!template) return []

            if (template.containerMeta) {
                const containerMeta = JSON.parse(template.containerMeta)
    
                if (containerMeta) {
                    template = { ...template, ...containerMeta, content: containerMeta.data, example: containerMeta.sampleText, url: containerMeta.mediaUrl }
                }
            }

            return [{ type: 'template', body: { ...template, originalType: template.type, type: 'template' }, fromMe: true }]
        }
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                this.selectedCampaign = null
                this.template = null
            })
        },
        async getCampaigns() {
            this.loading = true

            const channelId = this.user.channelId || this.user.roleId
            
            const resp = await api.getCampaigns(channelId)
            
            if(resp.statusCode === 200) {
                const campaigns = resp.campaigns.filter(campaign => !campaign.deleted).sort((a, b) => b.createdAt.localeCompare(a.createdAt))

                const promise = campaigns.map(async el => {
                    const statusResp = await api.getCampaignStatus(channelId, el._id)

                    if (statusResp.statusCode === 200) {
                        if (el.cancel)
                            el.status = "Cancelada"
                        else if (el.errorSending)
                            el.status = "Falha no Envio"
                        else if (el.sendingMessages.schedulingDate && Date.now() < new Date(el.sendingMessages.schedulingDate).getTime())
                            el.status = "Aguardando Envio"
                        else 
                            el.status = statusResp.statusCampaign?.queue ? "Em Andamento" : "Finalizada"
                    }
                })
                await Promise.all(promise)

                this.campaigns = campaigns
            } else {
                this.campaigns = []
            }

            this.loading = false
        },
        async getCampaignsFilter() {
            this.loading = true
            
            const channelId = this.user.channelId || this.user.roleId

            const resp = await api.getCampaignsFilter(channelId, this.filters)
            
            if(resp.statusCode === 200) {
                const campaigns = resp.campaigns.sort((a, b) => b.createdAt.localeCompare(a.createdAt))

                const promise = campaigns.map(async el => {
                    const statusResp = await api.getCampaignStatus(channelId, el._id)

                    if (statusResp.statusCode === 200) {
                        if (el.cancel)
                            el.status = "Cancelada"
                        else if (el.errorSending)
                            el.status = "Falha no Envio"
                        else if (el.sendingMessages.schedulingDate && Date.now() < new Date(el.sendingMessages.schedulingDate).getTime())
                            el.status = "Aguardando Envio"
                        else 
                            el.status = statusResp.statusCampaign?.queue ? "Em Andamento" : "Finalizada"
                    }
                })
                await Promise.all(promise)

                this.campaigns = campaigns
            } else {
                this.campaigns = []
            }

            this.loading = false
        },
        selectCampaign(campaign) {
            this.selectedCampaign = campaign
        },
        async sendCampaign() {
            if (!this.selectedCampaign)
                return this.$emit("msg", {
                    text: "Template não selecionado!"
                })

            const resp = await api.sendCampaign(this.user.channelId || this.user.roleId, this.selectedCampaign._id)

            if (resp.statusCode === 200) {
                this.$emit("msg", {
                    text: "Template adicionado a fila!",
                    success: true
                })
                this.$bvModal.hide("send")
            } else {
                this.$emit("msg", {
                    text: "Ocorreu um erro!"
                })
            }
        },
        async deleteCampaign(campaign) {
            if (!this.deleting) {
                this.deleting = true
                
                if (!campaign) {
                    campaign = this.selectedCampaign
                }
    
                if (campaign) {
                    const resp = await api.deleteCampaign(this.user.channelId || this.user.roleId, campaign._id)

                    if (resp.statusCode === 200 || resp.statusCode === 201) {
                        this.$emit("msg", {
                            text: "Campanha removida com sucesso!",
                            success: true
                        })
                        
                        this.getCampaigns()

                        this.$bvModal.hide('deleteCampaign')
                    } else {
                        this.$emit("msg", {
                            text: "Ocorreu um erro!",
                            success: false
                        })
                    }
                }

                this.deleting = false
            }
        },
        async cancelCampaign(campaign) {
            if (!this.canceling) {
                this.canceling = true
                
                if (!campaign) {
                    campaign = this.selectedCampaign
                }
    
                if (campaign) {
                    const resp = await api.cancelCampaign(this.user.channelId || this.user.roleId, campaign._id)

                    if (resp.statusCode === 200 || resp.statusCode === 201) {
                        this.$emit("msg", {
                            text: "Campanha cancelada com sucesso!",
                            success: true
                        })
                        
                        this.getCampaigns()

                        this.$bvModal.hide('cancelCampaign')
                    } else {
                        this.$emit("msg", {
                            text: "Ocorreu um erro!",
                            success: false
                        })
                    }
                }

                this.canceling = false
            }
        },
        async setPreview(templateId) {
            this.template = null
            this.loadingMessages = true
            
            const campaignTemplates = await api.getHsmTemplates(this.user.channelId || this.user.roleId, 'campaign')
            const attendanceTemplates = await api.getHsmTemplates(this.user.channelId || this.user.roleId, 'active_communication')

            if(campaignTemplates.statusCode === 200) {
                const template = this.getTemplateFromArray(campaignTemplates.templates, templateId)

                if (template)
                    this.template = { ...template, type: 'campaign' }
            }

            if(attendanceTemplates.statusCode === 200) {
                const template = this.getTemplateFromArray(attendanceTemplates.templates, templateId)

                if (template)
                    this.template = { ...template, type: 'active_communication' }
            }

            this.loadingMessages = false
        },
        getTemplateFromArray(templates, id) {
            let template = templates.find(el => el.id === id)
                if (template) {
                    if (template.containerMeta) {           
                        const containerMeta = JSON.parse(template.containerMeta)

                        if (containerMeta) {
                            containerMeta.editTemplate = undefined
                            template = { ...template, ...containerMeta, content: containerMeta.data, example: containerMeta.sampleText, url: containerMeta.mediaUrl }
                        }
                    } else if (template?.components) {
                        template.elementName = template.name
                        template.content = template.components.find(el => el.type === 'BODY')?.text
                        template.languageCode = template.language
                        template.templateType = template.components.find(el => el.type === 'HEADER')?.format || 'TEXT'
                        template.header = template.components.find(el => el.type === 'HEADER')?.text
                        template.footer = template.components.find(el => el.type === 'FOOTER')?.text
                        template.buttons = template.components.find(el => el.type === 'BUTTONS')?.buttons
                        template.url = template.components.find(el => el.type === 'HEADER')?.example?.header_handle?.[0]

                        if (template.templateType === 'BODY') {
                            template.templateType = 'TEXT'
                        }
                    }

                    if (!template.buttons)
                        template.buttons = []
                }
            return template
        },
    }
}
</script>